export default [
    {
        name: 'print',
        icon: "preset-print",
        default: 7,
        layouts: [{
            name: '4a0',
            unit: "mm",
            width: 1682,
            height: 2378
        }, {
            name: '2a0',
            unit: "mm",
            width: 1189,
            height: 1682
        }, {
            name: 'a0',
            unit: "mm",
            width: 841,
            height: 1189
        }, {
            name: 'a1',
            unit: "mm",
            width: 594,
            height: 841
        }, {
            name: 'a2',
            unit: "mm",
            width: 420,
            height: 594
        }, {
            name: 'a2',
            unit: "mm",
            width: 420,
            height: 594
        }, {
            name: 'a3',
            unit: "mm",
            width: 297,
            height: 420
        }, {
            name: 'a4',
            unit: "mm",
            width: 210,
            height: 297
        }, {
            name: 'a5',
            unit: "mm",
            width: 148,
            height: 210
        }, {
            name: 'a6',
            unit: "mm",
            width: 105,
            height: 148
        }, {
            name: 'a7',
            unit: "mm",
            width: 74,
            height: 105
        }, {
            name: 'a8',
            unit: "mm",
            width: 52,
            height: 74
        }, {
            name: 'a9',
            unit: "mm",
            width: 37,
            height: 52
        }, {
            name: 'a10',
            unit: "mm",
            width: 26,
            height: 37
        }, {
            name: 'us-letter-portrait',
            unit: "in",
            width: 8.5,
            height: 11
        }, {
            name: 'us-letter-landscape',
            unit: "in",
            width: 11,
            height: 8.5
        }, {
            name: 'business-card',
            unit: "mm",
            width: 85,
            height: 55
        }, {
            name: 'flyer',
            unit: "in",
            width: 5,
            height: 7
        }, {
            name: 'postcard',
            unit: "mm",
            width: 148,
            height: 105
        }]
    }, {
        name: 'text.web',
        icon: "preset-web",
        layouts: [{
            name: 'blog-cover',
            unit: "px",
            width: 560,
            height: 315
        }, {
            name: 'blog-graphic',
            unit: "px",
            width: 800,
            height: 1200
        }, {
            name: 'website-small',
            unit: "px",
            width: 1024,
            height: 768
        }, {
            name: 'website-normal',
            unit: "px",
            width: 1280,
            height: 800
        }, {
            name: 'website-medium',
            unit: "px",
            width: 1366,
            height: 768
        }, {
            name: 'website-large',
            unit: "px",
            width: 1440,
            height: 900
        }, {
            name: 'website-huge',
            unit: "px",
            width: 1920,
            height: 1280
        }, {
            name: 'full-website',
            unit: "px",
            width: 1440,
            height: 3072
        }]
    }, {
        name: 'social',
        icon: "preset-social",
        layouts: [{
            name: 'facebook-cover',
            unit: "px",
            width: 851,
            height: 315
        }, {
            name: 'twitter-cover',
            unit: "px",
            width: 1500,
            height: 500
        }, {
            name: 'youtube-cover',
            unit: "px",
            width: 2560,
            height: 1440
        }, {
            name: 'google-cover',
            unit: "px",
            width: 1080,
            height: 608
        }, {
            name: 'linkedin-cover',
            unit: "px",
            width: 1440,
            height: 425
        }, {
            name: 'twitch-cover',
            unit: "px",
            width: 900,
            height: 480
        }, {
            name: 'twitter-post',
            unit: "px",
            width: 1024,
            height: 512
        }, {
            name: 'facebook-post',
            unit: "px",
            width: 940,
            height: 788
        }, {
            name: 'facebook-app',
            unit: "px",
            width: 810,
            height: 450
        }, {
            name: 'facebook-ad',
            unit: "px",
            width: 1200,
            height: 627
        }, {
            name: 'instagram-post',
            unit: "px",
            width: 1080,
            height: 1080
        }, {
            name: 'tumblr-graphic',
            unit: "px",
            width: 540,
            height: 810
        }, {
            name: 'pinterest-pin',
            unit: "px",
            width: 735,
            height: 600
        }, {
            name: 'twitch-video',
            unit: "px",
            width: 1206,
            height: 708
        }, {
            name: 'linkedin-banner',
            unit: "px",
            width: 646,
            height: 220
        }]
    }, {
        name: 'screen',
        icon: "preset-screen",
        layouts: [{
            name: "iPhone X",
            unit: "px",
            width: 375,
            height: 812
        }, {
            name: "iPhone 8 Plus",
            unit: "px",
            width: 414,
            height: 736
        }, {
            name: "iPhone 8",
            unit: "px",
            width: 375,
            height: 667
        }, {
            name: "iPhone SE",
            unit: "px",
            width: 320,
            height: 568
        }, {
            name: "Nexus 5",
            unit: "px",
            width: 360,
            height: 640
        }, {
            name: "Apple Watch 38mm",
            unit: "px",
            width: 272,
            height: 340
        }, {
            section: "55f6e10de679477c7bca92c4",
            name: "Apple Watch 42mm",
            unit: "px",
            width: 312,
            height: 390
        }]
    }, {
        name: 'screen',
        icon: "preset-tablet",
        layouts: [{
            name: "iPad 3/4/Air/Mini",
            unit: "px",
            width: 768,
            height: 1024
        }, {
            name: "iPad Pro 12.9in",
            unit: "px",
            width: 1024,
            height: 1366
        }, {
            name: "Nexus 7",
            unit: "px",
            width: 600,
            height: 960
        }, {
            name: "Nexus 9",
            unit: "px",
            width: 1024,
            height: 768
        }, {
            name: "Nexus 10",
            unit: "px",
            width: 1280,
            height: 800
        }, {
            name: "Surface Pro 3",
            unit: "px",
            width: 1440,
            height: 960
        }, {
            name: "Surface Pro 4",
            unit: "px",
            width: 1368,
            height: 912
        }]
    }
]
